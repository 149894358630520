import React from 'react'
import apis from '../api'
import { withStyles } from '@mui/styles'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useHandleError } from '../utils'
import { useForm } from 'react-hook-form'
import Form from './Form'
import { images, footer_mask } from '../constants'
import { Divider, Grid, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'

const styles = theme => ({
  appContainer: {
     [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    height: '100vh',
  },
  root: {
    paddingTop: '3rem',
    height: 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
    textAlign: 'left',
    zIndex: '10',
    padding: '0 2rem',
    [theme.breakpoints.up('md')]: {
      width: '600px',
    },
  },
  titleBlock: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginBottom: '6rem'
  },
  img: {
    maxWidth: '350px'
  },
  loginFooter: {
    bottom: '0',
    width: '100%',
    height: 'auto',
    paddingTop: '12vmax',
    zIndex: '0',
    background: 'linear-gradient(0deg, rgba(232, 182, 74, 0.05), rgba(232, 182, 74, 0.05)), #F0F0F0',
    mask: `url('${footer_mask}')`,
    maskSize: 'cover',
    maskPosition: 'top',
  },
  loginFooterCurve: {
    background: '#fff',
    height: '200px',
    marginTop: '-100px',
    borderRadius: '50%',
    width: '120%',
    marginLeft: '-10%'
  },
  loginFooterContent: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  loginFooterSponsorLogosContainer: {
    width: '100%'
  },
  loginFooterSponsorImgs: {
    maxWidth: '80px',
    maxHeight: '40px',
    height: 'auto',
    width: 'auto',
    filter: 'grayscale(100%)'
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: '#FFFFFF'
    }
  }
})

const Login = (props) => {
  const formHook = useForm()

  const handleError = useHandleError()

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const onSubmit = (data) => {
    apis.login(data).then(res => {
      if ((res.data || {}).goTo) {
        navigate(res.data.goTo, { replace: true })

        window.location.reload()
      }
    }).catch(err => {
      handleError(err)
    })
  }

  const userIv = searchParams.get('iv')

  const userData = searchParams.get('data')

  if (userIv && userData) {
    apis.loginCmts({
      data: userIv + ':' + userData
    }).then(res => {
      if ((res.data || {}).goTo) {
        navigate(res.data.goTo, { replace: true })

        window.location.reload()
      }
    }).catch(err => {
      handleError(err)

      // TODO migliorare gestione errore nel caso di login proveniente da eelisa communities
    })
  }

  const { classes } = props

  const sponsorImages = Object.values(images)

  // scroll to the bottom to show the form
  let isOpen = true
  const handleClickScroll = () => {
    const element = document.getElementById('form')

    if (isOpen) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    isOpen = !isOpen;
  }

  return (
    <div className={classes.appContainer}>
      <div className={classes.root}>
        <span className={classes.titleBlock}>
          <img src={require('../images/logo.png')} alt='Logo' className={classes.img}></img>
        </span>
        <Typography variant='h3' marginBottom={'2rem'} fontWeight={'700'} fontSize={'36px'}>
          Sign in
        </Typography>
        <Typography variant='subtitle1' fontSize={'18px'}>
          If you are already registered in the EELISA Communities platform, please click below and follow the provided instructions.
        </Typography>
        <Button 
          href='https://community.eelisa.eu/research-network/' 
          variant='contained' 
          sx={{ margin: '4rem 0' }} >
            Sign in with Eelisa communities
        </Button>
        <Typography variant='subtitle1' textAlign={'left'} marginBottom={'1rem'} fontStyle={'italic'}>
          - OR -
        </Typography>
        <Accordion
          elevation={0}
          sx={{ backgroundColor: 'transparent' }} >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: '0'}} >
            <Link onClick={handleClickScroll}>Login with provided credentials</Link>
          </AccordionSummary>
          <AccordionDetails id='form' sx={{ padding: 0 }}>
            <Form
            formName={'login'}
            onSubmit={onSubmit}
            formHook={formHook}
             />
          </AccordionDetails>
        </Accordion>
      </div>
      <Accordion classes={{ root: classes.MuiAccordionroot }} sx={{}}>
        <footer className={classes.loginFooter}>
          <div className={classes.loginFooterContent}>
            <Grid width={{lg: '60vw', xs:'100vw'}}>
              <Grid item xs={12} sx={{ my: 2 }} margin={{lg: '0', xs: '0 2rem'}} paddingBottom= '2rem' textAlign={{lg:'left', xs:'center'}}>
                  <Typography variant="subtitle2" fontWeight='800' fontSize='14px' >
                    Our Partners
                  </Typography>
                  <Divider sx={{ my: 1 }} color='#000' />
              </Grid>
              <div className={classes.loginFooterSponsorLogosContainer}>
                <Grid container alignItems='center' justifyContent={{lg: 'space-between', xs: 'flex-start'}} paddingBottom='2rem'>
                  {sponsorImages.map((image, index) => (
                    <Grid item display='flex' justifyContent='center' lg={1} md={3} xs={4} sx={{ my: 1 }} key={`sponsor_image-${index + 1}`}>
                      <a href={image.url} target='_blank' rel='noopener noreferrer'>
                        <img src={image.image} alt="Our Partners" loading="lazy" title='Loghi Sponsor' className={classes.loginFooterSponsorImgs} />
                      </a>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
          </div>
        </footer>
      </Accordion>
    </div>
  )
}


export default withStyles(styles)(Login)
