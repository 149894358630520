import React from 'react'
import apis from '../api'
import { withStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { useHandleError } from '../utils'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import Form from './Form'

const styles = theme => ({
  root: {
    padding: 30
  },
  input: {
    marginBottom: '20px !important',
    display: 'block !important',
    width: '50%',
    '& .MuiInputBase-root': {
      width: '100%'
    }
  }
})

const Register = (props) => {
  const formHook = useForm()

  const navigate = useNavigate()

  const handleError = useHandleError()

  const onSubmit = (data) => {
    apis.register(data)
      .then(res => {
        navigate('/login')

        toast.success('Account created!')
      })
      .catch(err => {
        handleError(err)
      })
  }

  const { classes } = props

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>Crete a new account</h1>
      <Form
        formName={'register'}
        formHook={formHook}
        onSubmit={onSubmit} />
    </div>
  )
}


export default (withStyles(styles)(Register))
