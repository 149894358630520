import React, { useEffect, useState } from 'react'
import { withStyles } from '@mui/styles'
import apis from '../api'
import { useHandleError } from '../utils'
import { Card, CardContent, Grid, Icon, Link, List, ListItem, ListItemIcon, ListItemText, Typography, Breadcrumbs } from '@mui/material'
import ProjectComponent from './Project/ProjectComponent'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

const styles = theme => ({
  root: {
    padding: 30,
    marginBottom: 80
  },
  stickyBottom: {
    position: 'fixed',
    bottom: '0%',
    width: '100%',
    height: '80px',
    backgroundColor: '#F3F3F3',
  },
  breadcrumbs: {
    marginBottom: '20px !important'
  },
  breadcrumb_link: {
    color: theme.palette.primary.main + '!important',
    textDecoration: 'underline !important'
  }
})

const ProjectPage = (props) => {
  const params = useParams()

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const handleError = useHandleError()

  const [breadcrumbs, setBreadcrumbs] = useState([])

  const [data, setData] = useState(null)

  const { classes, pageType } = props

  useEffect(() => {
    if (!params.id || !searchParams.get('match')) {
      return navigate('/unauthorized', { replace: true })
    }

    apis.getItem(pageType + '/view', {
      id: params.id,
      match: searchParams.get('match').split(','),
      projectId: params.projectId
    }).then(res => {
      const data = (res.data || {}).data

      setBreadcrumbs(data.breadcrumbs)

      setData(data)
    }).catch(err => {
      handleError(err)
    })
  }, [])

  if (!data) {
    return ''
  }

  const {
    project,
    contacts = []
  } = data

  return (
    <div className={classes.root}>
      {(breadcrumbs ?
        <Breadcrumbs aria-label="breadcrumbs" className={classes.breadcrumbs}>
          {breadcrumbs.map((breadcrumb, index) => (
            <Link
              underline="hover"
              color="inherit"
              href={breadcrumb.value}
              key={index}
              className={classes.breadcrumb_link}
            >
              {breadcrumb.label}
            </Link>
          ))}
          <Typography color="text.primary">{project.title}</Typography>
        </Breadcrumbs>
        : ''
      )}
      <Grid
        container
        spacing={2}
        justifyContent='flex-start'
        alignItems='flex-start'
      >
        <ProjectComponent project={project} key={project.title + 1} isSingle/>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant='h6' gutterBottom>
                Contacts
              </Typography>
              <List>
                {contacts.map((contact, index) => {
                  const linkProps = contact.type === 'to' ? {
                    component: Link,
                    href: contact.href || contact.value,
                    target: '_blank'
                  } : {}

                  return (<ListItem key={'contact' + index} {...linkProps}>
                    {contact.icon ? (
                      <ListItemIcon>
                          <Icon>{contact.icon}</Icon>
                      </ListItemIcon>
                    ) : ''}
                    <ListItemText
                      primary={contact.value}
                      primaryTypographyProps={{ style: { whiteSpace: 'normal', wordWrap: 'break-word' } }}
                    />
                  </ListItem>)
                }) }
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default (withStyles(styles)(ProjectPage))