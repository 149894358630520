import { Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { withStyles } from '@mui/styles'
import { Controller } from 'react-hook-form'

const styles = theme => ({
  tag: {
    background: theme.palette.primary.main,
    height: '100%',
    display: 'flex',
    padding: '0.2rem 1rem',
    margin: '0 0.5rem 0 0',
    justifyContent: 'center',
    alignContent: 'center',
    color: theme.palette.text.default,
    borderRadius: '30px'
  },
  text: {
    whiteSpace: 'nowrap'
  }
})

const Tag = ({ classes, data }) => {
  return (
    <Box className={classes.tag}>
      <Stack direction='row' gap={1}>
        <Typography className={classes.text}>{data}</Typography>
      </Stack>
    </Box>
  )
}

const Tags = (props) => {
  const { classes, field: data, control } = props

  return (
    <Controller
      name={data.name}
      control={control}
      defaultValue={''}
      render={({ field }) => {
        return <TextField
          fullWidth
          variant='outlined'
          label={data.label}
          margin='none'
          placeholder={field.value ? '' : 'No items'}
          inputProps={{ readOnly: true }}
          InputProps={{
            startAdornment: (
              <Box sx={{ margin: '0 0.2rem 0 0', display: 'flex' }}>
                {(field.value || []).map((data, index) => {
                  return (
                    <Tag data={data} key={index} classes={classes} />
                  )
                })}
              </Box>
            )
          }}
        />
      }}
    />
  )
}

export default withStyles(styles)(Tags)
