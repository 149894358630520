import React from 'react'
import { withStyles } from '@mui/styles'
import { ListItem, Chip, List as ListMui } from '@mui/material'

const styles = theme => ({
  horiziontalListCell: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 !important',
    overflow: 'auto'
  },
  horiziontalListCellItem: {
    padding: '5px !important',
    width: 'auto !important'
  }
})

const HorizontalChipCell = (props) => {
  const {
    classes,
    items,
    key = ''
  } = props

  if (!Array.isArray(items)) {
    return ''
  }

  return (
    <ListMui className={classes.horiziontalListCell}>
      {(items || []).map((chipItem, chipIndex) => {
        const style = {}

        if (chipItem.color) {
          style.backgroundColor = chipItem.color
        }

        return (
          <ListItem className={classes.horiziontalListCellItem} key={key + chipIndex}>
            <Chip label={chipItem.label} style={style}/>
          </ListItem>
        )
      })}
    </ListMui>
  )
}

export default withStyles(styles)(HorizontalChipCell)
