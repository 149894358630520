import React, { useEffect, useState } from 'react'
import apis from '../api'
import { withStyles } from '@mui/styles'
import { IconButton, Button, Icon, Badge, Breadcrumbs, Typography } from '@mui/material'
import { Delete, Edit, AddCircle } from '@mui/icons-material'
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { useHandleError } from '../utils'
import Tooltip from '@mui/material/Tooltip'
import ConfirmModal from './Modal/ConfirmModal'
import Table from './Table'
import HorizontalChipCell from './Table/HorizontalChipCell'
import BorderLinearProgress from './Common/BorderLinearProgress'
import { getGridDateOperators, getGridNumericOperators, getGridSingleSelectOperators, getGridStringOperators } from '@mui/x-data-grid'

const styles = theme => ({
  root: {
    padding: 30
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '1rem'
  },
  title: {
    margin: '0 !important'
  },
  table: {
    border: '1px solid grey'
  },
  thead: {
    fontWeight: '900 !important'
  },
  tableElem: {
    padding: 20,
    borderBottom: '1px solid grey'
  },
  button: {
    marginLeft: '15px !important'
  },
  tableInfo: {
    marginLeft: '15px',
    borderLeft: '1px solid black',
    paddingLeft: '15px'
  },
  breadcrumbs: {
    marginBottom: '20px !important'
  },
  breadcrumb_link: {
    color: theme.palette.primary.main,
  }
})

const cleanParams = (params) => {
  const cleanedParams = { ...params }

  const toBeDeleted = [
    'rows',
    'sort',
    'order',
    'q',
    'page'
  ]

  toBeDeleted.forEach(k => delete cleanedParams[k])

  return cleanedParams
}

const List = (props) => {
  const [bool, setBool] = useState([])
  const [open, setOpen] = useState(false)
  const [text, setText] = useState('')
  const [currentId, setCurrentId] = useState('')
  const [action, setAction] = useState('')
  const [onClick, setOnClick] = useState('')
  const [pageConfig, setPageConfig] = useState(null)
  const params = useParams()
  const { pathname } = useLocation()

  const toggleModal = () => {
    setOpen(!open)
  }

  const navigate = useNavigate()

  const handleError = useHandleError()

  const [breadcrumbs, setBreadcrumbs] = useState()

  const { classes, pageType } = props

  const deleteItem = (url, id) => {
    apis.deleteItem(url, { ...cleanParams(params) , id }).then(res => {
      setBool(!bool)
    }).catch(err => {
      handleError(err)
    })
  }

  const openModal = (id, text, action, onClick) => {
    setText(text)

    setCurrentId(id)

    setAction(action)

    setOnClick(() => onClick)

    toggleModal()
  }

  useEffect(() => {
    apis.loadPage(pageType, params).then(res => {
      setPageConfig(res.data.data)

      setBreadcrumbs(res.data.data.breadcrumbs)
    }).catch(err => {
      handleError(err)
    })
  }, [pathname])

  if (!pageConfig) {
    return ''
  }

  const {
    title,
    createUrl,
    columns = [],
    rowActions = [],
    tableProps = {},
    tableActions = []
  } = pageConfig

  const table = {
    columns: [
      ...(tableProps.hasBadge ? [{
        field: 'badge',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        align: 'left',
        minWidth: 20,
        maxWidth: 20,
        renderCell: params => (
          params.value ? <Badge color='primary' variant='dot'/> : <div/>
        )
      }] : []),
      ...columns.map(column => {
        const toReturn = {
          field: column.name,
          headerName: column.label,
          minWidth: 130,
          flex: 1,
          headerAlign: 'left',
          sortable: !column.disableSort
        }

        switch (column.type || 'string') {
          case 'chips':
            toReturn.sortable = false

            toReturn.renderCell = (params) => (
              <HorizontalChipCell items={params.value || []} />
            )

            toReturn.type = 'singleSelect'

            toReturn.valueOptions = column.filterOptions

            toReturn.filterOperators = getGridSingleSelectOperators().filter(({ value }) =>
              !['not'].includes(value)
            )

            break
          case 'progress':
            toReturn.renderCell = (params) => (
              <BorderLinearProgress variant='determinate' value={params.value || 0} sx={{width: '100%'}} />
            )

            if (column.filterOperators) {
              toReturn.filterOperators = getGridNumericOperators().filter(({ value }) =>
                column.filterOperators.includes(value)
              )
            }

            toReturn.type = 'number'

            break
          case 'date':
            toReturn.filterOperators = getGridDateOperators().filter(({ value }) =>
              !['isNotEmpty', 'isEmpty'].includes(value)
            )

            break
          case 'number':
            if (column.filterOperators) {
              toReturn.filterOperators = getGridNumericOperators().filter(({ value }) =>
                column.filterOperators.includes(value)
              )
            }

            toReturn.type = column.type

            break
          default:
            if (column.filterOperators) {
              toReturn.filterOperators = getGridStringOperators().filter(({ value }) =>
                column.filterOperators.includes(value) && !['isAnyOf'].includes(value)
              )
            } else {
              toReturn.filterOperators = getGridStringOperators().filter(({ value }) =>
                !['isAnyOf'].includes(value)
              )
            }

            toReturn.type = column.type || 'string'

            break
        }

        return toReturn
      }),
      ...(rowActions.length ? [{
        field: 'actions',
        headerName: '',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        align: 'right',
        minWidth: 40 * rowActions.length,
        renderCell: (params) => (
          <div>
            {rowActions.map((action, actionIndex) => {
              switch (action.type) {
                case 'to':
                  return (
                    <Tooltip title={action.tooltip} key={actionIndex}>
                      <IconButton
                        onClick={e => {
                          e.preventDefault()

                          navigate(`${action.url}/${params.row.id}`)
                        }}>
                        {action.icon ?
                          (<Icon>{action.icon}</Icon>)
                        : <Edit />}
                      </IconButton>
                    </Tooltip>
                  )
                case 'delete':
                  return <Tooltip title='Delete' key={actionIndex}>
                    <IconButton
                      onClick={() => openModal(
                        params.row.id,
                        'Are you sure to delete this item?',
                        'Delete',
                        () => deleteItem(action.url, params.row.id)
                      )}
                      color='error'>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                default:
                  return ''
              }
            })}
          </div>
        )
      }] : [])
    ],
    query: {
      url: pageType + 's',
      search: '',
      page: 0,
      pageSize: 10,
      order: {
        field: '',
        sort: ''
      }
    },
    config: tableProps,
    tableActions
  }

  return (
    <div className={classes.root}>
      {(breadcrumbs ?
        <Breadcrumbs aria-label="breadcrumbs" className={classes.breadcrumbs}>
          {breadcrumbs.map((breadcrumb, index) => (
            <Link
              underline="hover"
              color="inherit"
              to={breadcrumb.value}
              key={index}
              className={classes.breadcrumb_link}
            >
              {breadcrumb.label}
            </Link>
          ))}
          <Typography color="text.primary">{title}</Typography>
        </Breadcrumbs>
        : ''
      )}
      <ConfirmModal
        open={open}
        toggleModal={toggleModal}
        title={text}
        param={currentId}
        onClick={onClick}
        action={action}
      />
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>{title}</h1>
        {createUrl ?
          <Button
            startIcon={<AddCircle />}
            href={createUrl}
            variant='contained'
          >
            Add new
          </Button> : ''
        }

      </div>
      <Table table={table} refresh={bool} queryParams={params}></Table>
    </div>
  )
}

export default (withStyles(styles)(List))
