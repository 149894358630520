import {
  FormPage,
  List,
  Login,
  MatchPage,
  Register,
  Unauthorized,
  ProjectPage,
  FAQsPage,
  ClustersPage
} from '../components'

export const indexRoutes = (appConfig) => {
  return [
    {
      paths: [
        '/',
        '/login',
      ],
      component: Login,
      props: {
        pageType: 'login'
      }
    },
    {
      paths: [
        '/register',
      ],
      component: Register,
      props: {
        pageType: 'register'
      }
    },
    {
      paths: [
        '/unauthorized',
      ],
      component: Unauthorized,
      props: {
        pageType: 'unauthorized'
      }
    },
    {
      paths: [
        '/user/list',
      ],
      component: List,
      props: {
        pageType: 'user'
      }
    },
    {
      paths: [
        '/user/form',
        '/user/form/:id',
      ],
      component: FormPage,
      props: {
        pageType: 'user'
      }
    },
    {
      paths: [
        '/label/:type/list',
      ],
      component: List,
      props: {
        pageType: 'label'
      }
    },
    {
      paths: [
        '/label/:type/form',
        '/label/:type/form/:id',
      ],
      component: FormPage,
      props: {
        pageType: 'label'
      }
    },
    {
      paths: [
        '/project/list',
      ],
      component: List,
      props: {
        pageType: 'project'
      }
    },
    {
      paths: [
        '/project/form',
        '/project/form/:id',
      ],
      component: FormPage,
      props: {
        pageType: 'project'
      }
    },
    {
      paths: [
        '/project/view/:id',
        '/project/:projectId/view/:id',
      ],
      component: ProjectPage,
      props: {
        pageType: 'project'
      }
    },
    {
      paths: [
        '/match/list',
        '/project/match/list/:projectId',
      ],
      component: List,
      props: {
        pageType: 'match'
      }
    },
    {
      paths: [
        '/match/view/:ids',
        '/project/:projectId/match/view/:ids'
      ],
      component: MatchPage,
      props: {
        pageType: 'match'
      }
    },
    {
      paths: [
        '/match-report/list',
        '/project/match-report/list/:projectId',

      ],
      component: List,
      props: {
        pageType: 'match-report'
      }
    },
    {
      paths: [
        '/match-report/view/:ids',
        '/project/:projectId/match-report/view/:ids',
      ],
      component: MatchPage,
      props: {
        pageType: 'match-report'
      }
    },
    {
      paths: [
        '/faq',
      ],
      component: FAQsPage,
      props: {
        pageType: 'faq'
      }
    },
    {
      paths: [
        '/project-report/list',
      ],
      component: List,
      props: {
        pageType: 'project-report'
      }
    },
    {
      paths: [
        '/user-report/list',
      ],
      component: List,
      props: {
        pageType: 'user-report'
      }
    },
    {
      paths: [
        '/clusters',
      ],
      component: ClustersPage,
      props: {
        pageType: 'cluster'
      }
    },
  ]
}