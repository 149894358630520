import React from 'react'
import { Controller } from 'react-hook-form'
import { withStyles } from '@mui/styles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const styles = theme => ({})

const CheckboxField = (props) => {
  const { field: data, control } = props

  return (
    <Controller
      name={data.name}
      control={control}
      rules={{ required: data.required }}
      defaultValue={''}
      render={({ field }) =>
        <FormControlLabel
          control={<Checkbox />}
          label={data.label}
          {...field}
        />}
    />
  )
}

export default withStyles(styles)(CheckboxField)
