import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './containers/App'

import './index.css'
import 'react-toastify/dist/ReactToastify.css'

const NewProvider = () => {
  return (
    <BrowserRouter forceRefresh={true}>
      <App />
    </BrowserRouter>
  )
}

const root = createRoot(document.getElementById('root'))

root.render(<NewProvider />)
