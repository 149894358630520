import React from 'react'
import { ToastContainer, Zoom } from 'react-toastify'
import PropTypes from 'prop-types'
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material'
import { enUS as dataGridEN } from '@mui/x-data-grid'
import { enUS as pickerEN } from '@mui/x-date-pickers'
import { withStyles } from '@mui/styles'
import {
  MainNavBar,
} from '../../components'
import EERoutes from '../../routes'


const theme = createTheme({
  typography: {
    useNextVariants: true
  },
  appVars: {
    navBarHeight: 64,
    sideBarWidth: {
      main: 64,
      open: 240
    },
    searchBoxHeight: 60,
  },
  palette: {
    background: {
      default: '#fff',
      title: '#F0F0F7'
    },
    text: {
      default: '#fff',
      primary: '#000'
    },
    primary: {
      main: '#D7673E',
      light: '#fff0eb',
      dark: '#ac5637'
    },
    secondary: {
      main: '#717171'
    },
    error: {
      main: '#f44336',
      light: '#ff7961',
      dark: '#ba000d'
    },
    warning: {
      main: '#ff9800',
      light: '#ffc947',
      dark: '#c66900'
    },
    success: {
      main: '#4caf50',
      light: '#80e27e',
      dark: '#087f23'
    },
    info: {
      main: '#2196f3',
      light: '#6ec6ff',
      dark: '#0069c0',
      badge: '#3B86FF',
    },
    placeholder: {
      main: '#F1F1F3',
      dark: '#a1a0ae',
    }
  },
},
  dataGridEN,
  pickerEN)

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    maxHeight: '100%',
    minHeight: '100%',
  },
  container: {
    maxWidth: '100%',
    minWidth: '100%'
  }
})

const App = (props) => {
  const { classes } = props

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <ToastContainer
          position="bottom-right"
          transition={Zoom}
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover
          theme="colored"
        />
        <div className={classes.container}>
          <MainNavBar>
            <EERoutes />
          </MainNavBar>
        </div>
      </div>
    </ThemeProvider>
  )
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default (withStyles(styles, { withTheme: true })(App))
