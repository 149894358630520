import React, { useState, useEffect } from 'react'
import Base from './Base'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { NavLink, useNavigate, Link, useLocation } from 'react-router-dom'
import { useHandleError } from '../../utils'
import apis from '../../api'
import { toast } from 'react-toastify'

const styles = theme => ({
  actions: {
    marginTop: '30px !important'
  },
  button: {
    marginLeft: '20px !important'
  },
  breadcrumb_link: {
    color: theme.palette.primary.main,
  }
})

const Form = (props) => {
  const {
    classes,
    formName,
    onSubmit,
    isNew,
    content,
    formHook,
    enctype,
    loadData,
    reloadTrigger,
    hideActions,
    buttonAction,
    queryParams
  } = props

  const { handleSubmit } = formHook

  const navigate = useNavigate()

  const { pathname } = useLocation()

  const [form, setForm] = useState({
    fields: [],
    actions: []
  })

  const [breadcrumbs, setBreadcrumbs] = useState()

  const handleError = useHandleError()

  const formProps = {}

  if (enctype) {
    formProps.encType = enctype
  }

  useEffect(() => {
    apis.loadForm(formName, queryParams).then(res => {
      const {
        form,
        goTo,
        breadcrumbs
      } = res.data || {}

      if (goTo) {
        return navigate(goTo)
      }

      setForm(form)

      setBreadcrumbs(breadcrumbs)
    }).catch(err => {
      handleError(err)
    })
  }, [pathname])

  useEffect(() => {
    if (form.fields.length && loadData) {
      loadData(form)
    }
  }, [form, reloadTrigger])

  const onError = () => {
    toast.error('Missing required fields')
  }

  if (!form) {
    return ''
  }

  return (
    <>
      {(breadcrumbs ?
        <Breadcrumbs aria-label="breadcrumbs">
          {breadcrumbs.map((breadcrumb, index) => (
            <Link
              underline="hover"
              color="inherit"
              to={breadcrumb.value}
              key={index}
              className={classes.breadcrumb_link}
            >
              {breadcrumb.label}
            </Link>
          ))}
          <Typography color="text.primary">{(isNew ? 'Add ' : 'Edit ') + form.title}</Typography>
        </Breadcrumbs>
        : ''
      )}

      <div className={classes.root}>
        {(form.title ?
          <h1>{(isNew ? 'Add ' : 'Edit ') + form.title}</h1>
          : ''
        )}
        <form onSubmit={handleSubmit(onSubmit, onError)} {...formProps}>
          <Grid container spacing={2}>
            {form.fields.map((field, index) => (
              (field.newOnly && !isNew) ? '' : (
                <Base field={field} formHook={formHook} key={index} content={content} isNew={isNew} />
              )
            ))}
          </Grid>
          {!hideActions && <div className={classes.actions}>
            {form.actions.map((action, index) => {
              switch (action.type) {
                case 'submit':
                  return (
                    <Button
                      variant={action.variant || 'contained'}
                      color={action.color || 'primary'}
                      type='submit'
                      key={index}>
                      {action.label}
                    </Button>)
                case 'redirect':
                  return (
                    <Button
                      variant={action.variant || 'contained'}
                      color={action.color || 'primary'}
                      className={classes.button}
                      component={NavLink}
                      key={index}
                      to={action.to}>
                      {action.label}
                    </Button>)
                case 'custom':
                  return (
                    <Button
                      variant={action.variant || 'contained'}
                      color={action.color || 'primary'}
                      className={classes.button}
                      onClick={buttonAction}
                      key={index}>
                      {action.label}
                    </Button>)
                default:
                  return ''
              }
            })}
          </div>}
        </form>
      </div>
    </>
  )
}

export default (withStyles(styles)(Form))
