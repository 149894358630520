import axios from 'axios'
import cookie from 'react-cookies'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import isArray from 'lodash/isArray'
import '../Config'

axios.defaults.withCredentials = true
axios.defaults.headers.common['Authorization'] = cookie.load('Authorization')
axios.defaults.baseURL = '/api'

export const ajaxCall = (obj) => {
  return new Promise((resolve, reject) => {
    axios(obj.config).then(res => {
      if (obj.download) {
        const blob = new Blob([res.data.data])

        const link = document.createElement('a')

        link.href = window.URL.createObjectURL(blob)

        link.download = res.data.filename

        link.click()

        return resolve()
      }

      return resolve(res)
    }).catch(error => {
      return reject(error)
    })
  })
}

export const useHandleError = () => {
  const navigate = useNavigate()

  const foo = (err) => {
    const response = err.response

    const status = response ? response.status : 0

    let msg = response ? response.data : err.message

    if (msg.errors) {
      msg = msg.errors.map(e => e.message).join('; ')
    } else {
      msg = msg.error || msg || 'Some error occurred'
    }

    switch (status) {
      case 400:
        toast.error(msg)
        break
      case 401:
        navigate('/login')
        break
      case 403:
        navigate('/unauthorized', { replace: true })
        break
      case 422:
        toast.error('Validation errors: ' + (msg.message || msg))

        return (response.data || {}).errors
      default:
        toast.error(msg)
    }
  }

  return foo
}

export const clsx = classes => {
  return (isArray(classes) ? classes : [classes]).filter(item => !(!item)).join(' ')
}