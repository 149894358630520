import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { withStyles } from '@mui/styles'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { has } from 'lodash'
import { FormHelperText } from '@mui/material'

const styles = theme => ({})

const Password = (props) => {
  const { field: data, control, formState } = props

  const { errors } = formState

  const [show, setShow] = useState(false)

  return (
    <Controller
      name={data.name}
      control={control}
      defaultValue={''}
      rules={{ required: data.required }}
      render={({ field }) =>
        <FormControl variant='outlined' fullWidth sx={{ display: data.hidden ? 'none' : 'flex'}}>
          <InputLabel htmlFor={data.name}>{data.label}</InputLabel>
          <OutlinedInput
            fullWidth
            name={data.name}
            id={data.name}
            type={show ? 'text' : 'password'}
            label={data.label}
            error={data.required ? has(errors, data.name) : undefined}
            {...field}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => setShow(!show)}
                  onMouseDown={e => e.preventDefault()}
                  edge='end'
                >
                  {show ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText error={true}>{has(errors, data.name) && (data.errorMsg || '')}</FormHelperText>
        </FormControl>}
    />
  )
}

export default withStyles(styles)(Password)
