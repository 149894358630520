import React from 'react'
import { withStyles } from '@mui/styles'
import ModalComponent from './Modal'
import { Button } from '@mui/material'

const styles = theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginLeft: '15px !important'
  },
  modalTitle: {
    marginTop: '0 !important'
  }
})

const ConfirmModal = (props) => {
  const {
    classes,
    open,
    toggleModal,
    title,
    paragraph,
    paragraph1,
    param,
    onClick,
    action
  } = props

  return (
    <ModalComponent open={open} onClose={toggleModal}>
      <div className={classes.root}>
        <h2 className={classes.modalTitle}>{title}</h2>
        <p className={classes.modalParagraph}>{paragraph}</p>
        <p className={classes.modalParagraph}>{paragraph1}</p>
        <span>
          <Button
            variant='contained'
            onClick={() => { onClick(param); toggleModal(); }}>
            {action}
          </Button>
          <Button
            variant='outlined'
            color='secondary'
            className={classes.button}
            onClick={toggleModal}>
            Cancel
          </Button>
        </span>
      </div>
    </ModalComponent>
  )
}

export default withStyles(styles, { withTheme: true })(ConfirmModal)
