import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid'
import { withStyles } from '@mui/styles'
import { useHandleError } from '../../utils'
import apis from '../../api'
import SearchToolbar from './SearchToolbar'
import NoRowsImageOverlay from './NoRowsImageOverlay'
import { Button, Icon } from '@mui/material'

const styles = theme => ({
  root: {
    width: '100%'
  },
  table: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none'
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
      outline: 'none'
    },
    '&.MuiDataGrid-root .MuiDataGrid-toolbarContainer': {
      padding: '1rem 0 0 1rem'
    }
  },
  highlightedRow: {
    background: '#e1b75f14'
  }
})

const Table = (props) => {
  const { classes, table, refresh, setUser, user, queryParams} = props

  const { columns, query, getData, config, tableActions } = table

  const handleError = useHandleError()

  const [rows, setRows] = useState([])
  const [bool, setBool] = useState(false)
  const [pagination, setPagination] = useState({
    page: query.page,
    rows: query.pageSize,
    total: 0
  })
  const [filterModel, setFilterModel] = useState(query.filterModel || {
    items: []
  })

  const key = 'table-params-' + window.location.pathname.substring(0, window.location.pathname.indexOf('/list'))

  const getParams = () => {
    const currentParams = JSON.parse(sessionStorage[key] || '{}')

    const params = queryParams

    if (query.search || currentParams.q) {
      params.q = currentParams.q || query.search
    } else {
      delete params.q
    }

    if (query.order || currentParams.sort) {
      const sort = currentParams.sort || query.order.field

      params.sort = sort

      const order = currentParams.order || query.order.sort

      params.order = order
    }

    if (query.filterModel || currentParams.filterModel) {
      params.filterModel = query.filterModel || currentParams.filterModel
    }

    params.page = currentParams.page || query.page

    params.rows = currentParams.rows || query.pageSize

    return params
  }

  useEffect(() => {
    const url = query.url

    apis.loadTable(url, getParams()).then(res => {
      const data = getData ? getData(res.data.data) : res.data.data

      setRows(data)

      const {
        pagination,
        filterModel,
        user: dataUser
      } = res.data

      setPagination(pagination)

      setFilterModel(filterModel || {
        items: []
      })

      if (user && dataUser && !Object.keys(user).length) {
        setUser(dataUser)
      }

    }).catch(err => {
      handleError(err)
    })
  }, [query, refresh, bool])

  const updateParams = (newParams) => {
    const params = JSON.parse(sessionStorage[key] || '{}')

    Object.entries(newParams).forEach(([key, value]) => {
      params[key] = value
    })

    sessionStorage[key] = JSON.stringify(params)

    setBool(!bool)
  }

  const CustomToolbar = (props) => {
    return (
      <div>
        {!config.disableSearch ? <SearchToolbar {...props}/> : ''}
        <GridToolbarContainer>
          {config.columnsFilters ? <GridToolbarFilterButton/> : ''}

          {(tableActions || []).map((action, index) => (
              <Button
                color={action.color}
                size={action.size}
                startIcon={<Icon>{action.icon}</Icon>}
                key={index}
                onClick={e => {
                  switch (action.type) {
                    case 'download':
                      return apis.downloadFile(action.url, getParams()).catch(err => {
                        handleError(err)
                      })
                    default:
                      return null
                  }
                }}>
                {action.label}
              </Button>
            )
          )}
        </GridToolbarContainer>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={parseInt(pagination.rows)}
        paginationModel={{
          pageSize: parseInt(pagination.rows),
          page: parseInt(pagination.page),
          pageCount: Math.ceil(parseInt(pagination.total) / parseInt(pagination.rows))
        }}
        page={parseInt(pagination.page)}
        rowCount={parseInt(pagination.total)}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        filterModel={filterModel}
        className={classes.table}
        paginationMode='server'
        sortingMode='server'
        filterMode='server'
        pageSizeOptions={[10, 20, 50]}
        disableColumnMenu={!config.columnsFilters}
        disableSelectionOnClick
        disableColumnSelector
        disableDensitySelector
        autoHeight
        getRowClassName={params => {
          if (params.row.highlighted) {
            return classes.highlightedRow
          }

          return ''
        }}
        slots={{
          toolbar: !config.disableSearch && !config.columnsFilters && !(tableActions || []).length ? SearchToolbar : CustomToolbar,
          noRowsOverlay: NoRowsImageOverlay,
        }}
        slotProps={{
          toolbar: {
            ...(!config.disableSearch && !config.columnsFilters ? {setQuery: updateParams} : {}),
            name: key,
          },
          noRowsOverlay: filterModel.items.length > 0 && filterModel.items.findIndex(el => el.value || !!el.value) !== -1 ? {
            message: 'Nothing found that satisfies the selected filters'
          } : (config || {}).noRows
        }}
        onSortModelChange={
          order => {
            updateParams({
              order: order.length ? order[0].sort : null,
              sort: order.length ? order[0].field : null,
              page: 0
            })
          }
        }
        onPaginationModelChange={
          pagination => {
            updateParams({
              page: pagination.page,
              rows: pagination.pageSize
            })
          }
        }
        onFilterModelChange={
          newFilterModel => updateParams({
            filterModel: newFilterModel
          })
        }
      />
    </div>
  )
}

export default (withStyles(styles)(Table))
