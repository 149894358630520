import React from 'react'
import { withStyles } from '@mui/styles'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '90vh'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '3rem 2rem 2rem 2rem',
    borderRadius: '10px',
    position: 'relative',
    minWidth: 600,
    maxWidth: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  iconClose: {
    position: 'absolute',
    right: 15,
    top: 15,
    zIndex: 10,
    cursor: 'pointer'
  },
  '@media (max-width: 800px)': {
    lg: {
      maxWidth: 'calc(100% - 40px)',
      minWidth: 'calc(100% - 40px)'
    }
  }
})

const ModalComponent = (props) => {
  const { classes, onClose, children, open } = props

  return (
    <Modal aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description' open={open} onClose={onClose} className={classes.root} >
      <div className={classes.paper}>
        <CloseIcon
          aria-label='close'
          onClick={onClose}
          className={classes.iconClose}
        />
        {children}
      </div>
    </Modal>
  )
}

export default withStyles(styles, { withTheme: true })(ModalComponent)
