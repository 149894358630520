import React from 'react'
import { Divider, Grid, Typography } from '@mui/material'
import TitleTextGridItem from '../Common/TitleTextGridItem'
import HorizontalChipList from '../Common/HorizontalChipList'

const ProjectComponent = (props) => {
  const { project, isSingle } = props

  if (!project) {
    return ''
  }

  let gridOptions = {
    xs: 12,
    md: 6
  }

  if (isSingle) {
    gridOptions.md = 8
  }

  return (
    <Grid
      container
      item
      {...gridOptions}
      spacing={1.5}
    >
      <Grid item xs>
        <Typography variant='h4' gutterBottom align={isSingle ? 'left' : 'center'}>
          <strong>{project.title}</strong>
        </Typography>
      </Grid>
      <TitleTextGridItem title={'Owner'} text={project.owner}/>
      {project.status ? <TitleTextGridItem title={'Match Status'} sx={{textDecoration: 'underline'}} text={project.status}/> : <></>}
      {(project.labels || []).map((labelGroup, index) => {
        return (
          <Grid item xs={12} key={'project' + index + labelGroup.title}>
            <Typography variant='h6' gutterBottom>
              {labelGroup.title + ' Labels'}
            </Typography>
            <HorizontalChipList items={labelGroup.items} key={'project' + index + 'labels'} />
          </Grid>
        )
      })}
      {(project.info || []).map((info, index) => {
        return (
          <TitleTextGridItem title={info.label} text={info.value} key={'project' + index + info.label}/>
        )
      })}
      <TitleTextGridItem title={'Abstract'} text={project.abstract}/>
      {!isSingle ? (
        <Grid item xs={12}>
          <Divider sx={{display: { xs: 'block', md: 'none'}}}/>
        </Grid>
      ) : ''}
    </Grid>
  )
}

export default ProjectComponent
