import React from 'react'
import { Grid, Typography } from '@mui/material'
import { sanitize } from 'dompurify'

const TitleTextGridItem = (props) => {
  const { title, text, sx } = props

  return (
    <Grid item xs={12}>
      <Typography variant='h6' gutterBottom>
        {title}
      </Typography>
      <Typography
        variant='body1'
        gutterBottom
        sx={sx}
        dangerouslySetInnerHTML={{
          __html: sanitize(text)
        }}
      />
    </Grid>
  )
}

export default TitleTextGridItem
