import React from 'react'
import { withStyles } from '@mui/styles'
import { ListItem, Chip, List as ListMui } from '@mui/material'

const styles = theme => ({
  horiziontalChipList: {
    display: 'inline-block',
    padding: '0 !important'
  },
  horiziontalChipListItem: {
    padding: '5px !important',
    width: 'auto !important',
    float: 'left'
  }
})

const HorizontalChipList = (props) => {
  const {
    classes,
    items,
    key = ''
  } = props

  if (!Array.isArray(items)) {
    return ''
  }

  return (
    <ListMui className={classes.horiziontalChipList}>
      {(items || []).map((chipItem, chipIndex) => {
        const style = {}

        if (chipItem.color) {
          style.backgroundColor = chipItem.color
        }

        return (
          <ListItem className={classes.horiziontalChipListItem} key={key + chipIndex}>
            <Chip label={chipItem.label} style={style}/>
          </ListItem>
        )
      })}
    </ListMui>
  )
}

export default withStyles(styles)(HorizontalChipList)
