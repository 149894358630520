import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'
import ModalComponent from '../Modal/Modal'
import { withStyles } from '@mui/styles'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TitleTextGridItem from '../Common/TitleTextGridItem'
import { sanitize } from 'dompurify'

highchartsMore(Highcharts)

const styles = theme => ({
  modalRoot: {
    width: '100%',
    marginBottom: '0',
    maxHeight: '80vh',
    overflow: 'scroll'
  },
  modalTitle: {
    marginTop: '0 !important'
  }
})

const SplitPackedBubbleChart = (props) => {
  const { chart, classes } = props

  const handleSeriesClick = (event) => {
    event.preventDefault()

    setShowModal(true)

    setSelectedSeries(event.point.series)
  }

  const closeModal = () => {
    setShowModal(false)

    setSelectedSeries(null)
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [showModal, setShowModal] = useState(false)
  const [selectedSeries, setSelectedSeries] = useState(null)
  const [ chartOptions, setChartOptions ] = useState(null)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    };

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const options = {
      chart: {
        type: 'packedbubble',
        height: '550px',
      },
      title: {
        text: chart.title,
        align: 'left'
      },
      tooltip: {
        useHTML: true,
        pointFormat: '<b>{point.name}</b><br>{point.description}'
      },
      plotOptions: {
        packedbubble: {
          layoutAlgorithm: {
            splitSeries: true,
            gravitationalConstant: 0.1,
            seriesInteraction: false,
            dragBetweenSeries: false,
            parentNodeLimit: true
          },
          minSize: '20%',
          maxSize: '100%',
          zMin: 0,
          zMax: 1000,
          zIndex: 1,
          dataLabels: {
            enabled: false,
            format: '{point.name}',
            filter: {
              property: 'y',
              operator: '>',
              value: 250
            },
            style: {
              color: 'black',
              textOutline: 'none',
              fontWeight: 'normal'
            }
          }
        },
        series: {
          events: {
        //     legendItemClick: (event) => {
        //       event.preventDefault()
        //     }
            click: handleSeriesClick
          }
        }
      },
      series: (chart || {}).series || [],
      credits: false,
      legend: {
        labelFormat: '<strong>{name}</strong><br> - {userOptions.description}',
        // title: {
        //   text: 'Series Description'
        // },
        useHTML: true,
        align: isMobile ? 'center' : 'right',
        verticalAlign: isMobile ? 'bottom' : 'middle',
        // layout: 'vertical',
        // maxHeight: 200,
      }
    }

    setChartOptions(options)
  }, [chart, isMobile])

  if (!chart) {
    return ''
  }

  const modalData = {}

  if (selectedSeries) {
    modalData.title = selectedSeries.userOptions.name || 'n.d.'

    modalData.description = selectedSeries.userOptions.description || ''

    modalData.labels = '<ul>' + Object.entries(selectedSeries.userOptions.labels || {}).map(([labelType, values]) => {
      return `<li><strong>${labelType.toUpperCase()}</strong>: ${values.map(el => {
        return `${el.name} (${el.value.toFixed(1)}%)`
      }).join(', ')}</li>`
    }).join('') + '</ul>'

    modalData.projects = '<ul>' + selectedSeries.data.map(item => {
      return `<li>${item.name}</li>`
    }).join('') + '</ul>'

    modalData.institutes = '<ul>' + (selectedSeries.userOptions.institutes || []).map(item => {
      return `<li>${item}</li>`
    }).join('') + '</ul>'
  }

  return (
    <div>
      <HighchartsReact
        highcharts = { Highcharts }
        options = { chartOptions }
      />
      { modalData && selectedSeries &&
        <ModalComponent open={showModal} onClose={closeModal} classes={classes}>
          <div className={classes.modalRoot}>
            <h2 className={classes.modalTitle}>{modalData.title}</h2>
            <Grid
              container
              spacing={1.5}
            >
              <Grid item xs={12}>
                {modalData.description}
              </Grid>

              <TitleTextGridItem title={'Labels'} text={modalData.labels}/>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography variant='h6' gutterBottom>
                      Projects ({selectedSeries.data.length})
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails dangerouslySetInnerHTML={{
                      __html: sanitize(modalData.projects)
                    }}
                  />
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography variant='h6' gutterBottom>
                      Institutes ({(selectedSeries.userOptions.institutes || []).length})
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails dangerouslySetInnerHTML={{
                      __html: sanitize(modalData.institutes)
                    }}
                  />
                </Accordion>
              </Grid>
            </Grid>
          </div>
        </ModalComponent>
      }
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(SplitPackedBubbleChart)
