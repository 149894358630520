import React, { useEffect, useState } from 'react'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { Controller } from 'react-hook-form'
import { withStyles } from '@mui/styles'
import { has } from 'lodash'
import { FormHelperText } from '@mui/material'

const styles = theme => ({})

const Selector = (props) => {
  const { field: data, control, formState, content } = props

  const { errors } = formState

  const [options, setOptions] = useState(data.options)

  useEffect(() => {
    const tmpContent = content || {}

    if (data.optionsFrom) {
      setOptions(tmpContent[data.optionsFrom])
    }
  }, [content])

  return (
    <Controller
      name={data.name}
      control={control}
      defaultValue={''}
      rules={{ required: data.required }}
      render={({ field }) =>
        <FormControl fullWidth
          error={data.required ? has(errors, data.name) : undefined}>
          <InputLabel id={'label-' + data.name}>{data.label}</InputLabel>
          <Select
            labelId={'label-' + data.name}
            label={data.label}
            {...field}
            value={field.value || ''} >
            {options.map((op, index) => (
              <MenuItem key={index} value={op.value}>{op.label}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{has(errors, data.name) && (data.errorMsg || '')}</FormHelperText>
        </FormControl>
      }
    />
  )
}

export default withStyles(styles)(Selector)
