import React from 'react'
import { withStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Input from './Input'
import Password from './Password'
import Textarea from './Textarea'
import Selector from './Select'
import Date from './Date'
import Editor from './Editor'
import Tags from './Tags'
import Info from './Info'
import Checkbox from './Checkbox'
import Autocomplete from './Autocomplete'

const styles = theme => ({})

const fields = {
  input: Input,
  textarea: Textarea,
  password: Password,
  select: Selector,
  date: Date,
  editor: Editor,
  tags: Tags,
  info: Info,
  checkbox: Checkbox,
  autocomplete: Autocomplete
}

const Base = (props) => {
  const { field, content, formHook, isNew } = props

  const { control, watch, getValues, setValue, formState } = formHook

  if (!fields[field.type]) {
    return ''
  }

  const TmpField = fields[field.type]

  return (
    <Grid item xs={field.size || 12}>
      <TmpField
        field={field}
        control={control}
        content={content}
        watch={watch}
        isNew={isNew}
        getValues={getValues}
        formState={formState}
        setValue={setValue} />
    </Grid>
  )
}

export default withStyles(styles)(Base)
