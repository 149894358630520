import React from 'react'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  root: {
    paddingTop: '40vh',
    textAlign: 'center'
  }
})

const NotFound = (props) => {
  const { classes } = props

  return (
    <div className={classes.root}>
      <h1>Page not found :(</h1>
    </div>
  )
}

export default (withStyles(styles)(NotFound))
