import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Collapse from '@mui/material/Collapse'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Icon from '@mui/material/Icon'
import { useLocation, Link, matchPath } from 'react-router-dom'
import { withStyles } from '@mui/styles'
import apis from '../api'
import { useNavigate } from "react-router-dom"
import { useHandleError, clsx } from '../utils'
import { AccountCircle, ChevronLeft, ChevronRight, ExpandLess, ExpandMore, Logout, ManageAccounts } from '@mui/icons-material'
import { Divider, Menu } from '@mui/material'
import { toast } from 'react-toastify'

const styles = theme => ({
  navbar: {
    height: '48px',
    minHeight: '48px !important',
    marginLeft: '-10px'
  },
  list: {
    padding: '0 !important',
  },
  navItems: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  button: {
    color: 'white !important',
    margin: '0 2rem !important',
    display: 'block'
  },
  drawer: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.background.default,
      width: '220px'
    },
    '&.closed .MuiPaper-root': {
      visibility: 'visible !important',
      transform: 'none !important',
      width: '50px',
      overflow: 'hidden',
    },
    '&.closed .MuiListItemText-root': {
      display: 'none'
    }
  },
  close: {
    height: '48px',
    paddingRight: '15px !important',
    display: 'flex !important',
    justifyContent: 'flex-end !important',
    alignItems: 'center !important',
    color: theme.palette.primary.main + ' !important',
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
  },
  openSidebar: {
    marginLeft: '220px !important'
  },
  mainContent: {
    marginLeft: '50px'
  },
  sidebarItem: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.text.primary
    }
  },
  collapseHead: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center'
  },
  subitem: {
    backgroundColor: '#f5f5f5 !important'
  },
  brand: {
    display: 'flex',
    alignItems: 'baseline',
    height: '48px'
  },
  img: {
    height: '36px',
    display: 'inline-block',
    position: 'relative',
    top: '7px',
    color: '#fff'
  },
  title: {
    display: 'inline-block',
    margin: 0,
    fontSize: '1.5rem'
  },
  spacer: {
    width: '170px'
  },
  credits: {
    position: 'absolute',
    bottom: 0,
    padding: '0 1rem',
    opacity: '.6',
    fontSize: '.9rem',
    textAlign: 'center',
    width: '100%',
    lineHeight: '1.2rem'
  }
})

const MenuItem = styled(ListItem)(({ active }) => ({
  padding: '8px 16px 8px 8px',
  paddingLeft: '11px',
  borderLeft: '5px solid transparent',
  minHeight: '45px',
  '&:hover': {
    backgroundColor: '#fff0eb !important'
  },
  ...(active && {
    borderLeft: '5px solid #D7673E',
    backgroundColor: '#fff0eb !important'
  }),
  '& .MuiListItemIcon-root': {
    minWidth: '35px'
  }
}))

const MainNavBar = (props) => {
  const navigate = useNavigate()

  const [userId, setUserId] = useState(null)
  const [userName, setUserName] = useState(null)
  const [items, setItems] = useState([])
  const [bool, setBool] = useState(true)
  const [open, setOpen] = useState(false)
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [subitems, setSubitems] = useState([])
  const [profileAnchorEl, setProfileAnchorEl] = useState(null)
  const openProfileMenu = Boolean(profileAnchorEl)

  const handleError = useHandleError()

  const logout = () => {
    apis.logout().then(res => {
      navigate('/login')

      setBool(!bool)
    }).catch(err => {
      handleError(err)
    })
  }

  useEffect(() => {
    apis.loadNavbar().then(res => {
      const {
        userId,
        userName,
        navItems = []
      } = res.data

      setItems(navItems)

      setUserId(userId)

      setUserName(userName)

      const tmpElems = []

      navItems.forEach(item => {
        if (item.subitems) {
          item.subitems.forEach(subitem => {
            tmpElems.push(subitem.link)
          })
        }
      })

      setSubitems(tmpElems)
    }).catch(err => {
      handleError(err)
    })
  }, [bool])

  useEffect(() => {
    const { pathname } = location

    const isInCollapse = subitems.some(elem => pathname.includes(elem))

    if (isInCollapse) {
      setCollapseOpen(true)
    }
  }, [subitems])

  const handleToggleMenu = () => {
    setOpen(!open)
  }

  const handleCollapse = () => {
    setOpen(true)

    setCollapseOpen(!collapseOpen)
  }

  const handleProfileClick = (event) => {
    setProfileAnchorEl(event.currentTarget)
  }
  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  const location = useLocation()

  const isActive = (element) => {
    const { pathname } = location

    return matchPath({
      path: element,
    }, pathname)
  }

  const { classes } = props

  if (!items.length) {
    return <div>{props.children}</div>
  }

  const currentYear = () => {
    return new Date().getFullYear()
  }

  return (
    <>
      <Drawer open={open} variant="persistent" className={clsx([classes.drawer, open ? '' : 'closed'])}>
        <IconButton onClick={handleToggleMenu} className={classes.close}>
          {open ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
        <List className={classes.list}>
          {items.map((item, index) => {
            const component = item.subitems ?
              (
                <div key={index}>
                  <MenuItem button onClick={handleCollapse} active={isActive(item.matchPath || item.link) && !collapseOpen ? 'true' : undefined}>
                    <ListItemIcon>
                      <Icon>{item.icon}</Icon>
                    </ListItemIcon>
                    <div className={classes.collapseHead}>
                      <ListItemText className={classes.sidebarItem} primary={item.label} />
                      {collapseOpen ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  </MenuItem>
                  <Collapse in={collapseOpen} timeout="auto">
                    {item.subitems.map((item, index) => {
                      return (
                        <Link to={item.link} key={index} style={{ textDecoration: 'none' }}>
                          <MenuItem button
                            className={classes.subitem}
                            active={isActive(item.matchPath || item.link) ? 'true' : undefined}>
                            <ListItemIcon>
                              <Icon>{item.icon}</Icon>
                            </ListItemIcon>
                            <ListItemText className={classes.sidebarItem} primary={item.label} />
                          </MenuItem>
                        </Link>
                      )
                    }
                    )}
                  </Collapse>
                </div>
              ) : (
                <Link to={item.link} key={index} style={{ textDecoration: 'none' }} onClick={() => setCollapseOpen(false)}>
                  <MenuItem button active={isActive(item.matchPath || item.link) ? 'true' : undefined}>
                    <ListItemIcon>
                      <Icon>{item.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText className={classes.sidebarItem} primary={item.label} />
                  </MenuItem>
                </Link>
              )

            return component
          })}
        </List>
        <p className={clsx([classes.credits, 'credits'])}>
          {currentYear()} © EElisa
          <br></br>
          by <a target='_blank' rel="noreferrer" href='https://www.inera.it/'>Inera srl</a> - v 1.0.0
        </p>
      </Drawer>
      <AppBar position="static">
        <Toolbar disableGutters className={classes.navbar}>
          <Box className={classes.navItems}>
            <span className={classes.spacer}></span>
            <span className={classes.brand}>
              <img src={require('../images/logo_nav.png')} alt='Logo' className={classes.img}></img>
            </span>
            <IconButton
              className={classes.button}
              id='profile-button'
              aria-controls={openProfileMenu ? 'profile-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openProfileMenu ? 'true' : undefined}
              onClick={handleProfileClick}
            >
              <AccountCircle/>
            </IconButton>
            <Menu
              id='profile-menu'
              anchorEl={profileAnchorEl}
              open={openProfileMenu}
              onClose={handleProfileClose}
              MenuListProps={{
                'aria-labelledby': 'profile-button',
              }}
            >
              <MenuItem>
                <ListItemText>Hi {userName || ''}</ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => {
                handleProfileClose()

                if (userId) {
                  navigate('/user/form/' + userId)
                } else {
                  toast.error('User not found. Please logout and login again.')
                }
              }}>
                <ListItemIcon>
                  <ManageAccounts/>
                </ListItemIcon>
                <ListItemText>Edit profile</ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem onClick={logout}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <div className={clsx([classes.mainContent, open ? classes.openSidebar : ''])}>{props.children}</div>
    </>
  )
}

export default withStyles(styles)(MainNavBar)
