import React, { useEffect, useState } from 'react'
import { withStyles } from '@mui/styles'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Container } from '@mui/material'
import TextField from '@mui/material/TextField'

import apis from '../api'

const styles = (theme) => ({
  root: {
    padding: 30,
    marginBottom: 80,
    '& .MuiPaper-root': {
      color: 'darkslategray',
      borderRadius: '0.6rem',
      boxShadow: 'none',
    },
    '& .MuiPaper-root:nth-last-child(1)::after': {
      position: 'absolute',
      left: 0,
      bottom: '0',
      right: 0,
      height: '1px',
      content: '""',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      opacity: '1',
    }
  },
  title: {
    margin: '0 !important'
  },
  ul: {
    listStyle: 'none',
    paddingLeft: 0
  },
  li: {
    marginBottom: 10
  }
})

const FAQsPage = (props) => {
  const { classes, pageType } = props

  const [faqData, setFaqData] = useState(null)

  const [searchKeyword, setSearchKeyword] = useState('')

  useEffect(() => {
    apis
      .loadPage(pageType)
      .then((res) => {
        setFaqData(res?.data?.data)
      })
      .catch((error) => {
        console.error('Error fetching FAQ data:', error)
      })
  }, [])

  // handle the search and filter the FAQ data
  const handleSearch = (event) => {
    const keyword = event.target.value.toLowerCase()
    setSearchKeyword(keyword)
  }

  // Filter the FAQ data based on the search keyword
  const filteredFAQData = faqData?.content?.map(({ category, data }) => ({
    category,
    data: data?.filter(
      ({ question, answer }) =>
        question?.toLowerCase().includes(searchKeyword) ||
        (answer?.toLowerCase().includes(searchKeyword))
    )
  }))

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>{faqData?.title || 'FAQs'}</h1>
      <Container maxWidth='lg' sx={{ mt: 5 }}>
        {/* Search input field */}
        <TextField
          label='Search'
          fullWidth
          variant='standard'
          value={searchKeyword}
          onChange={handleSearch}
        />
        {filteredFAQData?.map(
          ({ category, data }) =>
            // Only render the category if it has filtered data
            data.length > 0 && (
              <div key={category}>
                <Typography variant='h5' mb={3} mt={5}>
                  <strong>{category}</strong>
                </Typography>
                {data?.map(({ question, answer, steps, status }, index) => (
                  <Accordion className={classes.row} key={index} disableGutters>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'>
                      <Typography variant='h6'>{question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {steps && (
                        <div>
                          {Object.values(steps).map((step, index) => (
                            <p key={index}>
                              <strong>Step {index + 1}:</strong> {step}
                            </p>
                          ))}
                        </div>
                      )}
                      {answer && <span>{answer}</span>}
                      {status && (
                        <ul className={classes.ul}>
                          {Object.entries(status).map(([key, value]) => (
                            <li key={key} className={classes.li}>
                              <strong key={key}>{key}:</strong> {value}
                            </li>
                          ))}
                        </ul>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            )
        )}
      </Container>
    </div>
  )
}

export default withStyles(styles)(FAQsPage)
