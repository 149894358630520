import React from 'react'
import Button from '@mui/material/Button'
import { useNavigate } from "react-router-dom"
import { withStyles } from '@mui/styles'

const styles = theme => ({
  root: {
    padding: 30
  }
})

const Unauthorized = (props) => {
  const { classes } = props

  const navigate = useNavigate()

  return (
    <div className={classes.root}>
      <h1>You are not allowed to view this page</h1>
      <Button variant="contained" onClick={() => navigate(-1, { replace: true })}>Go back</Button>
    </div>
  )
}

export default (withStyles(styles)(Unauthorized))
