import { ajaxCall } from '../utils'

const login = payload => {
  return ajaxCall({
    config: {
      url: `/login`,
      data: payload,
      method: 'post'
    },
  })
}

const loginCmts = payload => {
  return ajaxCall({
    config: {
      url: `/login-cmts`,
      data: payload,
      method: 'post'
    },
  })
}

const register = payload => {
  return ajaxCall({
    config: {
      url: `/register`,
      data: payload,
      method: 'post'
    },
  })
}

const logout = () => {
  return ajaxCall({
    config: {
      url: `/logout`,
      method: 'get'
    },
  })
}

export const userApis = {
  login,
  loginCmts,
  logout,
  register
}
