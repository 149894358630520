import React from 'react'
import apis from '../api'
import { withStyles } from '@mui/styles'
import { useNavigate, useParams } from 'react-router-dom'
import { useHandleError } from '../utils'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import Form from './Form'

const styles = theme => ({
  root: {
    padding: 30
  },
  input: {
    marginBottom: '20px !important',
    paddingRight: '20px !important',
    width: '50%'
  },
  save: {
    marginRight: '10px !important'
  }
})

const FormPage = (props) => {
  const formHook = useForm()

  const { setValue, setError } = formHook

  const params = useParams()

  const handleError = useHandleError()

  const navigate = useNavigate()

  const isNew = !params.id

  const { classes, pageType } = props

  const handleSaveItem = (payload) => {
    Object.entries(payload).forEach(([key, val]) => {
      if (val === '') {
        payload[key] = null
      }
    })

    apis.saveItem(pageType + '/save', params, payload).then(res => {
      toast.success(res.data.message || 'Item saved')

      navigate(res.data.goTo || -1)
    }).catch(err => {
      (handleError(err) || []).forEach(validationErr => {
        setError(validationErr.path , { type: 'custom', message: validationErr.message })
      })
    })
  }

  const loadData = () => {
    if (params.id) {
      apis.getItem(pageType, params).then(res => {
        Object.entries(res.data.item).forEach(([key, value]) => {
          setValue(key, value || '')
        })
      }).catch(err => {
        handleError(err)
      })
    }
  }

  return (
    <div className={classes.root}>
      <Form
        formName={pageType}
        onSubmit={handleSaveItem}
        formHook={formHook}
        isNew={isNew}
        loadData={loadData}
        queryParams={params} />
    </div>
  )
}

export default (withStyles(styles)(FormPage))
