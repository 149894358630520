import React, { useState } from 'react'
import { Search } from '@mui/icons-material'
import Box from '@mui/material/Box'
import { withStyles } from '@mui/styles'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

const styles = theme => ({
  toolbar: {
    borderBottom: '1px solid #e0e0e0',
    padding: '0 1.2rem',
    '& .MuiFormControl-root': {
      width: '33%',
      float: 'right',
      '& .MuiInputBase-root': {
        height: '45px'
      }
    }
  }
})

const SearchToolbar = (props) => {
  const { classes, setQuery, name } = props

  const [value, setValue] = useState(JSON.parse(sessionStorage[name] || '{}').q || '')

  return (
    <Box className={classes.toolbar}>
      <TextField
        id='search'
        variant='standard'
        placeholder='Search'
        value={value}
        onChange={
          e => {
            setValue(e.target.value)

            setQuery({
              q: e.target.value,
              page: 0
            })
          }
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Search />
            </InputAdornment>
          )
        }}
      />
    </Box>
  );

}

export default (withStyles(styles)(SearchToolbar))
