import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { Controller } from 'react-hook-form'
import { withStyles } from '@mui/styles'
import { has } from 'lodash'
import { Chip } from '@mui/material'

const styles = theme => ({})

const SelectAdd = (props) => {
  const { field: data, control, getValues, content, readOnly, formState } = props

  const { errors } = formState

  const [options, setOptions] = useState(data.options)

  useEffect(() => {
    const tmpContent = content || {}

    if (data.optionsFrom) {
      setOptions(tmpContent[data.optionsFrom])
    }
  }, [content])

  const handleChange = (item, foo) => {
    const value = item && item.value ? item.value : item

    foo(value)
  }

  const validationRules = {}

  if (data.required && data.minLength > 0) {
    validationRules.minLength = (values) => values.length >= data.minLength
  }

  return (
    <Controller
      control={control}
      name={data.name}
      rules={{
        required: data.required,
        validate: validationRules
      }}
      defaultValue={getValues(data.name)}
      onChange={([, data]) => data}
      render={({ field }) => {
        const value = field.value && Number.isInteger(field.value) ? data.options.find(opt => opt.value === field.value) : field.value

        return (
          <Autocomplete
            freeSolo={data.creatable}
            multiple={data.isMulti}
            options={options || []}
            groupBy={data.grouped ? (option) => option.groupBy : null}
            getOptionLabel={(option) => option.label || option}
            disabled={readOnly}
            onChange={(e, data) => handleChange(data, field.onChange)}
            // onBlur={e => handleChange(e.target.value, field.onChange)}
            clearOnBlur={false}
            name={field.name}
            value={value || (data.isMulti ? [] : null)}
            isOptionEqualToValue={(option, value) => {
              return (option.value && option.value === value) || (option.value && value.value && option.value === value.value) || option === value
            }}
            renderInput={
              (params) => {
                return (
                  <TextField
                    {...params}
                    label={data.label}
                    error={data.required ? has(errors, data.name) : null}
                    helperText={(has(errors, data.name) && (data.errorMsg || '')) || (data.minLength ? 'Select at least ' + data.minLength + ' items' : null)}
                    placeholder={data.creatable ? 'Start writing and press Enter to add a new value' : null}
                  />)
              }
            }
            renderTags={(value, getTagProps) => {
              const sx = {}

              if (data.color) {
                sx.background = data.color
              }

              return value.map((option, index) => (
                <Chip
                  {...getTagProps(index)}
                  key={index}
                  label={option.label || option}
                  sx={sx}
                />
              ))
            }}
          />
        )
      }}
    />
  )
}

export default withStyles(styles)(SelectAdd)