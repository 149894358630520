import React, { useRef } from 'react'
import { withStyles } from '@mui/styles'
import { Editor } from '@tinymce/tinymce-react'
import { useController } from 'react-hook-form'

const styles = theme => ({
  titleEditor: {
    fontWeight: '500'
  }
})

const TextEditor = (props) => {
  const { field: data, control, getValues, classes } = props

  const { field } = useController({
    name: `${data.name}`,
    control,
    defaultValue: getValues(`${data.name}`)
  })

  const editorRef = useRef(null)

  return (
    <div ref={editorRef}>
      <h4 className={classes.titleEditor}>{data.label}</h4>
      <Editor
        onEditorChange={newV => field.onChange(newV.replace(/\n/g, ''))}
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        init={{
          height: 200,
          menubar: false,
          plugins: [],
          toolbar: 'bold italic underline',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          branding: false
        }}
        value={field.value}
        name={field.name}
      />
    </div>
  )
}

export default withStyles(styles)(TextEditor)
