import React from 'react'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { Controller } from 'react-hook-form'
import { withStyles } from '@mui/styles'
import { TextField } from '@mui/material'
import { has } from 'lodash'

const styles = theme => ({
  wrapper: {
    '& .MuiInputBase-root': {
      padding: '2px !important',
      width: '99% !important'
    }
  },
  textarea: {
    width: '100% !important',
    fontFamily: 'sans-serif !important',
    padding: '1rem !important',
    lineHeight: '1.3rem !important',
    fontSize: '1rem !important'
  }
})

const Textarea = (props) => {
  const { field: data, control, formState, classes } = props

  const { errors } = formState

  return (
    <Controller
      name={data.name}
      control={control}
      rules={{ required: data.required }}
      defaultValue={''}
      render={({ field }) =>
        <TextField
          className={classes.wrapper}
          multiline
          fullWidth
          label={data.label}
          error={data.required ? has(errors, data.name) : undefined}
          helperText={has(errors, data.name) && (data.errorMsg || '')}
          {...field}
          value={field.value || ''}
          InputProps={{
            inputComponent: TextareaAutosize,
            inputProps: {
              className: classes.textarea,
              minRows: 2,
              style: { resize: 'auto' }
            }
          }}
        />
      }
    />
  )
}

export default withStyles(styles)(Textarea)
