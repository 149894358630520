import React from 'react'
import TextField from '@mui/material/TextField'
import { Controller } from 'react-hook-form'
import { withStyles } from '@mui/styles'
import { has } from 'lodash'

const styles = theme => ({})

const Input = (props) => {
  const { field: data, control, formState } = props

  const { errors } = formState

  return (
    <Controller
      name={data.name}
      control={control}
      rules={{ required: data.required }}
      defaultValue={''}
      render={({ field }) =>
        <TextField
          fullWidth
          variant='outlined'
          label={data.label}
          error={data.required ? has(errors, data.name) : undefined}
          helperText={has(errors, data.name) && (data.errorMsg || '')}
          {...field}
          value={(field.value || field.value === 0) ? field.value : ''}
          disabled={data.readOnly} />}
    />
  )
}

export default withStyles(styles)(Input)
