import React from 'react'
import { withStyles } from '@mui/styles'

const styles = theme => ({})

const Info = (props) => {
  const { field: data } = props

  return (
    <p>
      <strong>{data.title}: </strong>
      {data.text}
    </p>
  )
}

export default withStyles(styles)(Info)
