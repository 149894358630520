import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '6px !important',
  borderRadius: 3,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3,
    backgroundColor: '#4caf50',
  },
}))

export default BorderLinearProgress