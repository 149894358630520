import React from 'react'
import TextField from '@mui/material/TextField'
import { Controller } from 'react-hook-form'
import { withStyles } from '@mui/styles'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import 'moment/locale/it'

const styles = theme => ({})

const Date = (props) => {
  const { field: data, control } = props

  const min = moment('1000-12-31')

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'it'}>
      <Controller
        name={data.name}
        control={control}
        defaultValue={''}
        render={({ field }) => {
          if (field.value === '') {
            field.value = null
          }

          return (<DesktopDatePicker
            fullWidth
            label={data.label}
            minDate={min}
            maxDate={moment()}
            inputFormat='DD/MM/YYYY'
            {...field}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />)
        }}
      />
    </LocalizationProvider>
  )
}

export default withStyles(styles)(Date)
