import { ajaxCall } from '../utils'
import { userApis } from './user'

const loadNavbar = () => {
  return ajaxCall({
    config: {
      url: `/navbar`,
      method: 'get'
    },
  })
}

const loadPage = (url, params) => {
  return ajaxCall({
    config: {
      url: url + 's/page',
      method: 'get',
      params
    },
  })
}

const loadTable = (url, params) => {
  return ajaxCall({
    config: {
      url,
      method: 'get',
      params
    },
  })
}

const loadForm = (name, params) => {
  return ajaxCall({
    config: {
      url: `/${name}/form`,
      method: 'get',
      params
    },
  })
}


const saveItem = (url, params, payload) => {
  return ajaxCall({
    config: {
      url,
      params,
      data: payload,
      method: 'post'
    },
  })
}

const deleteItem = (url, params) => {
  return ajaxCall({
    config: {
      url,
      params,
      method: 'delete'
    },
  })
}

const getItem = (url, params) => {
  return ajaxCall({
    config: {
      url,
      method: 'get',
      params
    },
  })
}

const downloadFile = (url, params) => {
  return ajaxCall({
    config: {
      url,
      method: 'get',
      params,
    },
    download: true
  })
}

const apis = {
  downloadFile,
  loadNavbar,
  loadTable,
  loadForm,
  loadPage,
  saveItem,
  deleteItem,
  getItem,
  ...userApis
}

export default apis
