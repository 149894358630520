// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
html {
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #3b3a53;
  line-height: 1.1 !important;
}

.tooltip {
  position: absolute;
  top: -23px;
  background-color: #848282;
  padding: 0px 8px;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  visibility: hidden;
}

.has-tooltip:hover .tooltip {
  visibility: visible;
}

.subitem {
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid #3b3a53;
}

.subitem .m-0 {
  margin-top: 0;
  margin-bottom: -1em;
}

.closed .credits {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,iCAAiC;EACjC,eAAe;EACf,cAAc;EACd,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf","sourcesContent":["body,\nhtml {\n  height: 100%;\n  font-family: \"Roboto\", sans-serif;\n  font-size: 14px;\n  color: #3b3a53;\n  line-height: 1.1 !important;\n}\n\n.tooltip {\n  position: absolute;\n  top: -23px;\n  background-color: #848282;\n  padding: 0px 8px;\n  border-radius: 4px;\n  color: #fff;\n  font-size: 12px;\n  visibility: hidden;\n}\n\n.has-tooltip:hover .tooltip {\n  visibility: visible;\n}\n\n.subitem {\n  margin-left: 1rem;\n  padding-left: 1rem;\n  border-left: 1px solid #3b3a53;\n}\n\n.subitem .m-0 {\n  margin-top: 0;\n  margin-bottom: -1em;\n}\n\n.closed .credits {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
