import { Box, Icon } from '@mui/material'
import { withStyles } from '@mui/styles'
import { sanitize } from 'dompurify'

const styles = theme => ({
  noRowsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  noRowsMessage: {
    fontSize: '1.2rem',
    textAlign: 'center'
  }
})

const NoRowsImageOverlay = (props) => {
  const { classes, message, icon } = props

  return (
    <div className={classes.noRowsContainer}>
      {icon ? <Icon sx={{ mb: 1 }}>{icon}</Icon> : ''}
      <Box className={classes.noRowsMessage} dangerouslySetInnerHTML={{
          __html: sanitize(message || 'No rows')
        }} >
      </Box>
    </div>
  )
}

export default (withStyles(styles)(NoRowsImageOverlay))