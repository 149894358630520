import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { indexRoutes } from './Config'
import { NotFound } from '../components'

const EERoutes = (props) => {
  const routes = indexRoutes()

  return (
    <Routes>
      {routes.map((route, routeIndex) => {
        return route.paths.map((path, pathIndex) => {
          const TmpComponent = route.component

          return (
            <Route path={path} key={routeIndex + '-' + pathIndex} element={<TmpComponent {...route.props}/>} />
          )
        })
      })}
      <Route path='*' element={<NotFound/>} />
    </Routes>
  )
}

export default EERoutes