const MATCH_STATUS = {
  created: 0,
  viewed: 1,
  accepted: 2,
  refused: 3,
  confirmed: 4
}

module.exports = {
  MATCH_STATUS
}