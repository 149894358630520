import React, { useEffect, useState } from 'react'
import { withStyles } from '@mui/styles'
import { NavLink, useNavigate, useParams, Link } from 'react-router-dom'
import apis from '../api'
import { useHandleError } from '../utils'
import { Button, Divider, Grid, Stack, Typography } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import HorizontalChipList from './Common/HorizontalChipList'
import ProjectComponent from './Project/ProjectComponent'
import { Add, CheckCircleOutline, Clear, HighlightOffOutlined, SentimentSatisfiedAltOutlined, Visibility } from '@mui/icons-material'
import { toast } from 'react-toastify'
import BorderLinearProgress from './Common/BorderLinearProgress'
import { MATCH_STATUS } from '../utils/consts'
import ConfirmModal from './Modal/ConfirmModal'
import confetti from 'canvas-confetti'

const styles = theme => ({
  root: {
    padding: 30,
    marginBottom: 80
  },
  stickyBottom: {
    position: 'sticky',
    bottom: '0%',
    width: '100%',
    height: '80px',
    backgroundColor: '#F3F3F3',
  },
  breadcrumbs: {
    marginBottom: '20px !important'
  },
  breadcrumb_link: {
    color: theme.palette.primary.main,
  }
})

const MatchPage = (props) => {
  const params = useParams()

  const navigate = useNavigate()

  const handleError = useHandleError()

  const [data, setData] = useState(null)

  const [breadcrumbs, setBreadcrumbs] = useState()

  const [open, setOpen] = useState(false)

  const [currentId, setCurrentId] = useState('')

  const toggleModal = () => {
    setOpen(!open)
  }

  const openModal = (id) => {
    setCurrentId(id)

    toggleModal()
  }

  const { classes, pageType } = props

  const ids = (params.ids || '').split(',')

  useEffect(() => {
    if (ids.length < 2) {
      return
    }

    apis.getItem(pageType, {...params, ids}).then(res => {
      const data = (res.data || {}).data

      const breadcrumbs = data.breadcrumbs || []

      setBreadcrumbs(breadcrumbs)

      setData(data)
    }).catch(err => {
      handleError(err)
    })
  }, [])

  if (!data) {
    return ''
  }

  const {
    match,
    project1,
    project2
  } = data

  const setMatchStatus = (status => {
    apis.saveItem(pageType + '/save', null, {
      ids,
      status,
      projectId: params.projectId
    }).then(res => {
      const {
        statusMatch,
        goTo,
        message
      } = res.data || {}

      toast.success(message || 'Item saved')

      if (statusMatch === MATCH_STATUS.confirmed) {
        setData({
          ...data,
          match: {
            ...data.match,
            status: statusMatch
          },
          project2: {
            ...data.project2,
            url: goTo
          },
        })

        openModal()

        confetti({
          particleCount: 300,
          spread: 100,
          origin: { y: 0.5 }
        })
      } else {
        navigate(goTo || -1)
      }
    }).catch(err => {
      handleError(err)
    })
  })

  let stickyBottom

  switch (match.status) {
    case MATCH_STATUS.accepted:
      stickyBottom = (
        <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center' sx={{height: '100%', mr: '80px'}}>
          <Stack direction='row' spacing={1} alignItems='center' sx={{ml: '20px'}}>
            <CheckCircleOutline color='success'/>
            <div>
              <Typography variant='h6'>
                You have already accepted this match.
              </Typography>
              <Typography variant='caption'>
                To view the contacts of the matched project's owner, he/she must accept the match too!
              </Typography>
            </div>
          </Stack>
          <Stack spacing={0.3}>
            <Typography variant='caption'>
              Changed your mind?
            </Typography>
            <Button
              startIcon={<Clear />}
              variant='outlined'
              color='error'
              onClick={() => {
                setMatchStatus(3)
              }}
            >
              I am not interested anymore
            </Button>
          </Stack>
        </Stack>
      )

      break
    case MATCH_STATUS.refused:
      stickyBottom = (
        <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center' sx={{height: '100%', mr: '80px'}}>
          <Stack direction='row' spacing={1} alignItems='center' sx={{ml: '20px'}}>
            <HighlightOffOutlined color='error'/>
            <Typography variant='h6'>
              You have refused this match.
            </Typography>
          </Stack>
          <Stack spacing={0.3}>
            <Typography variant='caption'>
              Changed your mind?
            </Typography>
            <Button
              startIcon={<Add />}
              variant='contained'
              color='success'
              onClick={() => {
                setMatchStatus(2)
              }}
            >
              Accept
            </Button>
          </Stack>
        </Stack>
      )

      break
    case MATCH_STATUS.confirmed:
      stickyBottom = (
        <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center' sx={{height: '100%', mr: '80px'}}>
          <Stack direction='row' spacing={1} alignItems='center' sx={{ml: '20px'}}>
            <SentimentSatisfiedAltOutlined color='success'/>
            <div>
              <Typography variant='h6'>
                Both of you have accepted this match.
              </Typography>
              <Typography variant='caption'>
                Click on 'VIEW MATCHED PROJECT' to view the contacts of the matched project's owner.
              </Typography>
            </div>
          </Stack>
          <Button
            startIcon={<Visibility />}
            variant='contained'
            color='primary'
            component={NavLink}
            to={project2.url}
          >
            View matched project
          </Button>
        </Stack>
      )

      break
    default:
      stickyBottom = (
        <Stack direction='row' spacing={2} justifyContent='flex-end' alignItems='center' sx={{height: '100%', mr: '80px'}}>
          <Button
            startIcon={<Add />}
            variant='contained'
            color='success'
            onClick={() => {
              setMatchStatus(2)
            }}
          >
            Accept
          </Button>
          <Button
            startIcon={<Clear />}
            variant='outlined'
            color='error'
            onClick={() => {
              setMatchStatus(3)
            }}
          >
            I am not interested
          </Button>
        </Stack>
      )

      break
  }

  return (
    <>
      <div className={classes.root}>
        {(breadcrumbs ?
          <Breadcrumbs aria-label="breadcrumbs" className={classes.breadcrumbs}>
            {breadcrumbs.map((breadcrumb, index) => {
              return (
                <Link
                  underline="hover"
                  color="inherit"
                  to={breadcrumb.value}
                  key={index}
                  className={classes.breadcrumb_link}
                >
                  {breadcrumb.label}
                </Link>
              )
            })}
            <Typography color="text.primary">Match Result</Typography>
          </Breadcrumbs>
          : ''
        )}
        <ConfirmModal
          open={open}
          toggleModal={toggleModal}
          title={`Congratulations!!!🎉 `}
          paragraph={<>The match between your project <b>"{project1.title}"</b> and <b>"{project2.title}"</b> has been accepted by both of you.</>}
          paragraph1={`You can now view the contacts of the matched project's owner.`}
          param={currentId}
          onClick={() => navigate(project2.url)
          }
          action='View matched project'
        />

        <div>
          <Typography variant='h6' gutterBottom>
            Match result
          </Typography>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Typography variant='body1' gutterBottom>
                  <u>Score</u>: {match.score}%
                </Typography>
                <BorderLinearProgress variant='determinate' value={match.score} sx={{width: 250}} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' gutterBottom>
                <u>Labels</u>:
              </Typography>
              <HorizontalChipList items={match.labels} />
            </Grid>
          </Grid>
        </div>
        <Divider sx={{my: 2}}/>
        <Grid
          container
          columnSpacing={2}
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <ProjectComponent project={project1} key={project1.title + 1}/>
          <Divider orientation='vertical' flexItem sx={{ mr: '-1px', display: { xs: 'none', md: 'block'}}}/>
          <ProjectComponent project={project2} key={project2.title + 2}/>
        </Grid>
      </div>
      {Number.isInteger(match.status) ? (<div className={classes.stickyBottom}>
        <Divider/>
        {stickyBottom}
      </div>) : null}
    </>
  )
}

export default (withStyles(styles)(MatchPage))