import React, { useEffect, useState } from 'react'
import { withStyles } from '@mui/styles'
import apis from '../api'
import { useHandleError } from '../utils'
import { Grid, Link, Typography, Breadcrumbs, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import SplitPackedBubbleChart from './Charts/SplitPackedBubbleChart'

const styles = theme => ({
  root: {
    padding: 30,
    marginBottom: 80
  },
  stickyBottom: {
    position: 'fixed',
    bottom: '0%',
    width: '100%',
    height: '80px',
    backgroundColor: '#F3F3F3',
  },
  breadcrumbs: {
    marginBottom: '20px !important'
  },
  breadcrumb_link: {
    color: theme.palette.primary.main + '!important',
    textDecoration: 'underline !important'
  }
})

const ClustersPage = (props) => {
  const handleError = useHandleError()

  const [breadcrumbs, setBreadcrumbs] = useState([])

  const [data, setData] = useState(null)

  const [run, setRun] = useState(0)

  const { classes, pageType } = props

  useEffect(() => {
    apis.loadPage(pageType, {
      run
    }).then(res => {
      const data = (res.data || {}).data

      setBreadcrumbs(data.breadcrumbs)

      setData(data)
    }).catch(err => {
      handleError(err)
    })
  }, [run])

  const onChange = (event) => {
    const value = event.target.value
    if (run !== value) {
      setRun(value)
    }
  }

  if (!data) {
    return ''
  }

  const {
    title,
    chart,
    select
  } = data

  return (
    <div className={classes.root}>
      {(breadcrumbs ?
        <Breadcrumbs aria-label="breadcrumbs" className={classes.breadcrumbs}>
          {breadcrumbs.map((breadcrumb, index) => (
            <Link
              underline="hover"
              color="inherit"
              href={breadcrumb.value}
              key={index}
              className={classes.breadcrumb_link}
            >
              {breadcrumb.label}
            </Link>
          ))}
          <Typography color="text.primary">{title}</Typography>
        </Breadcrumbs>
        : ''
      )}
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>{title}</h1>
      </div>
      <Grid
        container
        spacing={2}
        justifyContent='center'
      >
        {select &&
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id='input-select-runs'>{select.label}</InputLabel>
              <Select
                id='select-runs'
                labelId={'select-label-runs'}
                label={select.label}
                value={select.value || 0}
                onChange={onChange}
              >
                {select.options.map((op, index) => (
                  <MenuItem key={index} value={op.value}>{op.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        }
        {chart &&
          <Grid item xs={12}>
            <SplitPackedBubbleChart chart={chart} classes={classes}/>
          </Grid>
        }
      </Grid>
    </div>
  )
}

export default (withStyles(styles)(ClustersPage))