import bme from '../images/bme.webp'
import ecole from '../images/ecole.webp'
import fau from '../images/fau.webp'
import itu from '../images/itu.webp'
import psl from '../images/psl.webp'
import santanna from '../images/santanna.webp'
import sns from '../images/sns.webp'
import taras from '../images/taras.webp'
import upb from '../images/upb.webp'
import upd from '../images/upd.webp'

export default [
    {
      image: bme,
      url: 'https://www.bme.hu/?language=en'
    },
    {
      image: ecole,
      url: 'https://ecoledesponts.fr/en'
    },
    {
      image: fau,
      url: 'https://www.fau.eu/'
    },
    {
      image: itu,
      url: 'https://www.itu.edu.tr/en/homepage'
    },
    {
      image: psl,
      url: 'https://psl.eu/en'
    },
    {
      image: santanna,
      url: 'https://www.santannapisa.it/it'
    },
    {
      image: sns,
      url: 'https://www.sns.it/en'
    },
    {
      image: taras,
      url: 'http://www.univ.kiev.ua/en/'
    },
    {
      image: upb,
      url: 'https://upb.ro/en/'
    },
    {
      image: upd,
      url: 'https://www.upm.es/internacional'
    }
  ]
